<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense>
						<!-- GEOREPTYPEID -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`georeptypeid-mgeoreptypes`"
								v-model="model.georeptypeid"
								:label="$t('mgeoreptypes.georeptypeid')"
								:disabled="formDisabled"
								toplabel
								maxlength="3"
							></pui-text-field>
						</v-col>
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`desc-mgeoreptypes`"
								v-model="model.desc"
								:label="$t('mgeoreptypes.desc')"
								:disabled="formDisabled"
								toplabel
							></pui-text-field>
						</v-col>
						<!-- EXPDATE -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-date-field
								:id="`expdate-mgeoreptypes`"
								v-model="model.expdate"
								:label="$t('mgeoreptypes.expdate')"
								:disabled="formDisabled"
								toplabel
							></pui-date-field>
						</v-col>
					</v-row>
					<v-row dense> </v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'mgeoreptypes-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'mgeoreptypes'
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	computed: {},
	created() {}
};
</script>
